
class ZIDXPrivacyPolicy implements ZIDXPage{
    constructor() {

    }
    getPageClass():string{
        return "zidxPrivacyPolicyContainer";
    }
    getMatchURL():string{
        return "/privacy-policy/";
    }
    render(){
        // console.log("privacy policy");
    }
}